import React from "react";
import Discord from "../images/discord.png";
import Medium from "../images/medium.png";
import Twitter from "../images/twitter.png";
import s from "./Footer.module.scss";

const Footer = () => (
  <footer className={s.footer}>
    <span>Terraform Capital 2022</span>
    <form>
      <a
        href="https://terraformcapital.medium.com/"
        target="_blank"
        rel="noreferrer"
      >
        <img className={s.button} src={Medium} alt="" />
      </a>
      <a
        href="https://twitter.com/terra_money"
        target="_blank"
        rel="noreferrer"
      >
        <img className={s.button} src={Twitter} alt="" />
      </a>
      <a href="https://discord.gg/EuKCeGFb93" target="_blank" rel="noreferrer">
        <img className={s.button} src={Discord} alt="" />
      </a>
    </form>
  </footer>
);

export default Footer;
